import http from "../utils/http"

/**
 * 店铺列表
 * @param {object} params
 */
export function shopList(params) {
    return http({
        url: "/api/shop/page",
        data: params
    })
}

/**
 * 店铺列表
 * @param {object} params
 */
export function storeList(params) {
    return http({
        url: "/api/store/page",
        data: params
    })
}


/**
 * 是否关注店铺
 * @param {object} params
 */
export function shopIsSubscribe(params) {
    return http({
        url: "/api/shopmember/issubscribe",
        data: params,
        forceLogin: true
    })
}

/**
 * 是否关注门店
 * @param {object} params
 */
export function storeIsSubscribe(params) {
    return http({
        url: "/store/api/storemember/issubscribe",
        data: params,
        forceLogin: true
    })
}


/**
 * 关注店铺
 * @param {object} params
 */
export function addShopSubscribe(params) {
    return http({
        url: "/api/shopmember/add",
        data: params,
        forceLogin: true
    })
}

/**
 * 取消关注店铺
 * @param {object} params
 */
export function deleteShopSubscribe(params) {
    return http({
        url: "/api/shopmember/delete",
        data: params,
        forceLogin: true
    })
}

/**
 * 关注门店
 * @param {object} params
 */
export function addStoreSubscribe(params) {
    return http({
        url: "/store/api/storemember/add",
        data: params,
        forceLogin: true
    })
}

/**
 * 取消关注店铺
 * @param {object} params
 */
export function deleteStoreSubscribe(params) {
    return http({
        url: "/store/api/storemember/delete",
        data: params,
        forceLogin: true
    })
}